@import "themeColor";
@import "button";
@import "logo";
@import "loader";

@import "./news/news";
@import "./news/list";

@import "./setting/story";
@import "./setting/privacyPolicy";
@import "./setting/QA";

// 每個頁面距離底部的margin(防止被navbar覆蓋)
.page {
  margin-bottom: 5rem; }

// 用於多所賽況的標題
.notice-title {
  position: relative;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  margin: 2rem auto;
  margin-bottom: 3rem;
  letter-spacing: 1px;
  &::after {
    border-radius: 1000px;
    content: '';
    background: $btn-gradient;
    width: 30px;
    height: 7px;
    position: absolute;
    left: calc(50% - 30px / 2);
    bottom: -1.2rem; } }

// 用於聯絡我們成功訊息和舊報名成功訊息容器
.form-card {
  background-color: white;
  margin-top: 4rem;
  padding: .5rem 1rem;
  padding-bottom: 4rem; }

// 列表為空的空盒子
.empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  position: relative;
  img.icon {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
    opacity: .7; }
  span {
    font-size: .9rem;
    font-style: italic; } }

// 適用於 返回 Icon + Title 的 bar
.top-bar {
  position: relative;
  display: flex;
  align-items: center;
  .icon {
    z-index: 1; }
  .title {
    position: absolute;
    width: 100%;
    text-align: center; } }

.news-list {
  .card-content {
    position: relative;
    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50px;
      width: 100%;
      background: linear-gradient(to top, white 15%, rgba(white, 0) 100% ); } } }

