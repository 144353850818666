table.table th, table.table td {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important; }

table.table thead tr th {
  font-weight: 700; }

.popular {
  .icon-vote {
    position: fixed;
    bottom: 15vh;
    right: 4vh; }
  .table {
    text-align: center;
    .th-rank {
      width: 15%; }
    .th-song {
      width: 65%; }
    .th-vote {
      width: 20%; }
    .content {
      .user {
        display: block; }
      .song {
        display: block;
        font-weight: bolder; } } } }
