@import "../variable";

.setting {
  .list {
    background: white;
    margin: 1rem 0; } }

.update-success-remind {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color3;
  color: white;
  text-align: center;
  padding: .4rem 0;
  z-index: 1000;
  font-size: 14px;
  letter-spacing: 1px; }
