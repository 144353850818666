@import "../variable";

// 加入會員的標題
.join-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  margin-top: .5rem;
  span.add {
    margin-right: 1rem;
    @include gradient-text($btn-gradient); }
  .mcip-logo {
    justify-content: flex-start;
    padding: 0;
    width: auto; } }

// setting/profile 頁面
.profile {
  .contract {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0; } }
  .error-remind {
    color: red;
    font-weight: 600;
    display: block; } }


