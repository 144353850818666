@import "./variable";

.loader {
  width: 100%;
  text-align: center;
  padding: 3rem 0;
  display: inline-block;
  user-select: none;
  svg path, svg rect {
    fill: #ccc; }
  .text {
    color: #ccc;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-top: .5rem; } }
