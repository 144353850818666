.setting-qa {
  .category {
    margin-top: 1.5rem;
    .title {
      color: rgba(0, 0, 0, 0.54); }
    .expansion-panel {
      box-shadow: none;
      .expansion-panel-summary {
        padding-right: 0;
        padding-left: 0; }
      .expansion-panel-detail {
        padding-right: 0;
        padding-left: 0;
        white-space: pre-line; } } }
  .first-category {
    margin-top: .25rem; } }
