$bg-cover-height: 10rem;   // 頂部背景封面高度

// 頂部封面左右滑動動畫
@keyframes bg-gradient-animation {
  0% {
    background-position: 0% 50%; }

  50% {
    background-position: 100% 50%; }

  100% {
    background-position: 0% 50%; } }

// 頂部背景封面
.bg-cover {
  background-color: #333;
  background-size: 400% 400%;
  animation: bg-gradient-animation 10s ease infinite;
  height: $bg-cover-height;
  position: relative;
  width: 100%;
  &.gradient {
    background-image: $bg-gradient; }
  &.gradient-secondary {
    background-image: $btn-secondary-gradient; }
  // 半透明黑色遮罩
  &.mask::before {
    z-index: 0;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .6;
    position: absolute;
    background: #000; } }

// 頂部背景封面固定
.top-fixed {
  position: fixed; }
