@import "./variable";

.color1-big-btn {
  @include big-btn;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: $big-btn-gap-size;
  width: calc(100% - #{$big-btn-gap-size} * 2);
  display: block;
  .text {	 // 按鈕內的文字
    margin-top: .75rem;
    letter-spacing: 1px;
    font-size: 14px; } }

.form-school-img-1 {
  @include big-btn;
  display: block;
  background-color: lightgray;
  object-fit: cover;
  @include fixed-wh($form-school-img-size); }

.school-info-img-1 {
  @include big-btn;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lightgray;
  background-position: center;
  display: block;
  height: 100px;
  margin: 0 ($big-btn-gap-size / 2);
  margin-bottom: $big-btn-gap-size;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  &::after {
    content: '';
    padding-top: 100%;
    display: block; }
  & .mask {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    background: #000;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  h6 {
    position: absolute;
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: .9rem;
    padding: .5rem;
    margin: 0;
    bottom: 0;
    text-shadow: 0 0 1rem rgba(black ,.3);
    text-align: center;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    &::after {
      content: '';
      width: 20px;
      height: 4px;
      border-radius: 20px;
      text-align: center;
      display: block;
      background: white;
      margin: 8px auto 0 auto; } } }
