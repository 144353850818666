@import "../variable";

.lottery-attend-success-modal {
  text-align: center;
  .icon-wrapper {
    padding: 3rem 0 1.5rem 0;
    .icon {
      font-size: 100px;
      color: $color-success; } }
  .message {
    font-size: 1.5rem; }
  .button-wrapper {
    margin: 1rem 0; } }


.lottery-item {
  @include block-item-common; }

.detail-mask {
  z-index: 2;
  position: relative;
  height: 50px;
  margin-top: -50px;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(white, 0) 0%, rgba(white, 1) 70%); }
