@import "../variable";

@keyframes twinkle-border {
  from {
    border: solid 1px rgba(darken($color5, 100%), .2);
    box-shadow: $big-btn-shadow; }
  to {
    border: solid 1px rgba($color5, .5);
    box-shadow: 0 6px 15px rgba($color5, .1); } }

@keyframes update-time-shine {
  from {
    color: #26e1a5; }
  to {
    color: #1e1e1e; } }

$sleep-z-s-float-distance: 3rem;
$sleep-z-s-duration: 5s;

@keyframes sleep-z-s-float {
  0% {
    transform: none;
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    transform: translate($sleep-z-s-float-distance / 2, -$sleep-z-s-float-distance / 2);
    opacity: 1; }
  100% {
    transform: translate($sleep-z-s-float-distance, -$sleep-z-s-float-distance);
    opacity: 0; } }

@keyframes competition-item-text-show {
  from {
    opacity: 0;
    transform: translateY(1rem); }
  to {
    transform: none; } }

@keyframes heartbeat {
  0% {
    transform: scale(1); }
  5% {
    transform: scale(1.15); }
  10% {
    transform: scale(1.05); }
  15% {
    transform: scale(1.25); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

.competition {
  margin-bottom: 3rem;
  .header {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    .school {
      display: inline-block;
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 3rem;
      span {
        @include gradient-text($bg-gradient);
        @include gradient-text($btn-gradient); } }
    .group {
      font-size: 1.6rem;
      font-weight: 600;
      padding-left: .25rem;
      margin-bottom: .5rem; }
    .last-update {
      opacity: .5;
      display: block;
      font-size: .8rem;
      padding-left: .25rem;
      animation: update-time-shine 5s;
      animation-fill-mode: backwards; }
    .status {
      padding-left: .25rem;
      font-weight: 400;
      margin-top: .25rem; }
    &.scroll {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #f6f6f6;
      z-index: 100;
      margin: 0;
      padding: .75rem 1rem;
      opacity: 0;
      transition: opacity .4s;
      margin-bottom: 0;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .school {
        font-size: 1.6rem;
        line-height: normal;
        flex: 0 0 100%; }
      .group {
        font-size: 1.1rem;
        padding-left: .15rem;
        margin-bottom: .25rem; }
      .last-update {
        margin-left: .5rem; }
      .status {
        flex: 0 0 100%; }
      &.show {
        opacity: 1;
        transform: none; }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1.5rem;
        bottom: -1.5rem;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, #f6f6f6, rgba(#f6f6f6, 0));
        z-index: 500; } } }

  .tab-list {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: .5rem;
    padding-bottom: .3rem;
    display: flex;
    list-style: none;
    position: fixed;
    bottom: 3rem;
    left: .5rem;
    right: .5rem;
    width: auto;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    z-index: 1000;
    border-radius: 15px;
    .item {
      font-size: .9rem;
      font-weight: 500;
      padding: .2rem .5rem;
      border-bottom: 3px solid transparent;
      transition: all .2s;
      color: rgba(white, .9);
      cursor: pointer;
      &.active {
        @include gradient-text($btn-gradient); }
      &:active {
        user-select: none; }
      .icon {
        color: #26e1a5;
        margin-right: .2rem;
        font-size: .7rem;
        vertical-align: baseline; } } }

  .list {
    padding: 0;
    .item {
      border-radius: $border-radius;
      display: block;
      list-style: none;
      padding: 0 .5rem;
      margin: 1.25rem .1rem;
      transition: padding-bottom .5s, padding-top .5s;
      box-shadow: 0 6px 15px rgba(36, 37, 38, 0);
      border: solid 1px transparent;
      overflow: hidden;
      &.disabled {
        opacity: .4; }
      .index {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 300;
        transform: scale(1.3);
        .icon {
          display: none; } }
      .song {
        font-size: 14px;
        font-weight: 500; }
      .name {
        font-size: 14px;
        opacity: .8;
        font-weight: normal;
        &::before {
          content: '-';
          opacity: .5;
          font-size: 12px;
          margin: 0 .2rem; } }

      &.active {
        animation: twinkle-border 1s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        z-index: 90000;
        box-shadow: $big-btn-shadow;
        background-color: white;
        padding: .75rem .5rem;
        .info {
          display: flex;
          flex-direction: column; }
        .index {
          color: $color5;
          .number {
            display: none; }
          .icon {
            display: inline-block;
            animation: heartbeat 3s infinite; } }

        .song {
          display: inline-block;
          animation: competition-item-text-show .5s;
          animation-fill-mode: backwards;
          animation-delay: .2s;

          font-size: 1.1rem;
          margin-bottom: .2rem; }
        .name {
          animation: competition-item-text-show .5s;
          animation-fill-mode: backwards;
          animation-delay: .4s;
          &::before {
            content: '';
            margin: 0; } } } } } }

.empty-message {
  .sleeping-z-s {
    background: url('../../icons/moon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin: auto;
    width: 6rem;
    height: 6rem;
    margin-bottom: 1.5rem;
    opacity: .3;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../icons/z-char.svg');
      background-repeat: no-repeat;
      background-size: contain;
      animation: sleep-z-s-float $sleep-z-s-duration $sleep-z-s-duration / 2 linear;
      animation-iteration-count: infinite;
      opacity: 0; }
    &::after {
      background-image: url('../../icons/z-char.svg');
      animation-delay: 0s; } } }
