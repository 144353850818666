@import "../variable";
@import "./common";

$loader-element-max-width: 25rem;  // 偽載入框架元素之基準寬度


// [學校列表]
.school-list {
  .bg-cover {
    background-image: $bg-gradient; }
  // 放篩選器chip的容器
  .filter {
    margin: 1rem 0;
    // chip樣式
    .filter-chip {
      margin-bottom: .25rem;
      margin-right: .25rem; } }

  // 分組標題
  .list-title {
    font-size: .8rem;
    color: #999; }

  // 分割線
  .divider {
    height: 0;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: 1rem 0; }

  // 學校列表項目
  .school-item {
    display: flex;
    margin: 1rem 0;
    transition: $transition;
    align-items: center;
    user-select: none;
    // 學校頭像
    .avatar {
      box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
      border: 0;
      user-select: none;
      border-radius: $border-radius;
      display: block;
      background-color: lightgray;
      object-fit: cover;
      @include fixed-wh($avatar-size); }
    // 內容
    .content {
      margin-left: 1rem;
      .name, .period, .date, .deadline, .status {
        font-size: .8rem; }
      .name {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 1.1rem; }
      .period {
        font-weight: 500; }
      .date-list {
        display: flex;
        flex-wrap: wrap;
        .date {
          display: flex;
          align-items: center;
          color: #999;
          margin-right: 1rem;
          margin-bottom: 0;
          &::before {
            content: '';
            background-color: #999;
            display: inline-block;
            height: .25rem;
            width: .5rem;
            border-radius: .25rem;
            margin-right: .2rem; } } }
      .deadline {
        color: $color5;
        font-weight: 500; }
      .status {
        color: #999; } }

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
        background-color: rgba(black, .05); }
      &:active {
        transform: scale(.98); } }
    &.disabled {
      cursor: no-drop;
      opacity: .3;
      .avatar {
        @include fixed-wh($avatar-size * (2 / 3)); } } }

  // 偽框架 loader
  .loader {
    margin: 0;
    padding: 0;
    .school-item {
      .avatar {
        background: #ddd;
        border: 0; }
      .content {
        width: 100%;
        .name, .period, .date, .deadline {
          display: block;
          width: 60%;
          max-width: $loader-element-max-width * .6;
          background-color: #ddd;
          height: 1.2rem;
          margin-bottom: .4rem; }
        .period, .date, .deadline {
          height: .8rem;
          width: 50%;
          max-width: $loader-element-max-width * .5; }
        .period {
          width: 20%;
          max-width: $loader-element-max-width * .2; } } } } }
