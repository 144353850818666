// 所有共用的變數

@mixin gradient-text($bg-img) {
  background-clip: text;
  color: transparent;
  background-image: $bg-img; }

// MCIP Brand Color
//secondary color list usage:
//  color: nth($brand-secondary-list, 1)
$brand-primary: #00b9e6;
$brand-secondary-list: #48c0dc #b2f0ff #00476e;


// https://color.adobe.com/zh/Copia-de-Copy-of-Copy-of-Core-color-theme-11691632/?showPublished=true
$color1: #112F41;
$color2: #068587;
$color3: #4FB99F;
$color4: #F2B134;
$color5: #ED553B;
$color-set: $color1 $color2 $color3 $color4 $color5;
$color-success: #a4cc38;

// 顏色/漸層
$bg-gradient: linear-gradient(45deg, #2af1fb 0%, #2aeefb 10%, #34d2fb, #45bdfa, #50b0fa, #54adfa 70%, #bf9ff2 100%);
$btn-gradient: linear-gradient(270deg, nth($brand-secondary-list, 1), nth($brand-secondary-list, 1));
$btn-secondary-gradient: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);

// Big Button 變數
$big-btn-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);	// 按鈕預設陰影
$big-btn-hover-shadow: 0 6px 15px rgba(36, 37, 38, 0.13);	// 按鈕hover陰影
$big-btn-gap-size: 5px;	// 按鈕間距

// form 學校圖片長寬大小
$form-school-img-size: 120px;
$avatar-size: $form-school-img-size;

// transition
$transition: all .3s cubic-bezier(0.25, 0.8, 0.25, 1);

// 圓角大小
$border-radius: 15px;

// 等寬字形
$monospace-font: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;

// 按鈕的共同陰影樣式
@mixin btn-common {
  box-shadow: $big-btn-shadow;
  border: 0;
  transition: $transition;
  user-select: none;
  &:not(.disabled, :disabled) {
    &:hover {
      box-shadow: $big-btn-hover-shadow;
      cursor: pointer; }
    &:active {
      transform: scale(.95);
      filter: brightness(.95); } }
  &:focus {
    outline: none; }
  &.disabled, &:disabled {
    opacity: .7;
    filter: grayscale(100%);
    cursor: no-drop; } }

// Big Button 的混入
@mixin big-btn {
  @include btn-common;
  background-color: white;
  border-radius: $border-radius; }

// 圓角 input 混入
@mixin input-common {
  border: 1px solid rgba(#aaa, .3);
  background-color: white;
  color: $color2;
  transition: $transition;
  letter-spacing: .5px;
  text-align: left;
  outline: none;
  &:active, &:focus {
    outline: none; } }

@mixin fixed-wh($x) {
  width: $x;
  height: $x;
  min-width: $x;
  min-height: $x; }

@mixin block-item-common {
  box-shadow: $big-btn-shadow;
  border: 0;
  border-radius: $border-radius;
  margin-bottom: 1rem;
  background-color: white;
  overflow: hidden; }
