@import "./variable";

@for $i from 1 through length($color-set) {
  $c: nth($color-set, $i);
  .color#{$i} {
    color: $c; }
  .color#{$i}-bg {
    background-color: $c; }
  .color#{$i}-border {
    border: 1px solid $c; }
  .color#{$i}-border-bottom {
    border-bottom: 1px solid $c; } }

.form-group {
  margin: 1.2rem 0;
  label {
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: .2rem;
    padding-left: 2px; } }

.theme-input {
  @include input-common;
  border-radius: $border-radius;
  padding: .5rem 1rem;
  width: 100%;
  appearance: none;
  &:focus {
    &:not([readonly]) {
      border-color: rgba($color2, .5); } }
  &[readonly] {
    opacity: .7;
    color: darken(grey, 20%);
    background-color: rgba(grey, .2); } }

select.theme-input {
  appearance: none;
  position: relative;
  // 自定義的箭頭圖案
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat; }

.theme-input-members {
  @include input-common;
  border: 0;
  border-bottom: 1px solid rgba(#aaa, .3);
  border-radius: 0;
  padding: .25rem .5rem;
  &:focus {
    border-color: rgba($color2, .5); } }

.gradient-bg {
  background-image: $bg-gradient; }

.btn-secondary-bg {
  background-image: $btn-secondary-gradient; }

.gradient-btn {
  @include btn-common;
  border-radius: 1000px;
  background-image: $btn-gradient;
  color: white;
  padding: .6rem 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  text-align: center;
  &.secondary {
    background-image: $btn-secondary-gradient; }
  &.block {
    display: block;
    width: 100%; } }
