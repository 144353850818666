@import "./variable";

.mcip-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1.5rem 0;
  overflow: hidden;
  user-select: none;
  img {
    max-width: 300px;
    width: 100%;
    height: auto; } }
