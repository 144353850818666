.QA {
  .expansion-panel {
    box-shadow: none;
    .expansion-panel-summary {
      padding-right: 0;
      padding-left: 0; }
    .expansion-panel-detail {
      padding-right: 0;
      padding-left: 0;
      white-space: pre-line; } } }
