@import "../variable";
@import "./common";

// [報名表單]

@keyframes form-in {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    transform: none; } }

.form {
  .bg-cover {
    animation: none;
    background-size: cover;
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; }
  form {
    position: relative;
    margin-top: 6rem;
    background-color: white;
    padding: 1.5rem 1rem;
    animation: form-in .5s .2s;
    animation-fill-mode: backwards;
    .cancel {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      top: -3rem;
      left: 0; }
    .remind {
      strong {
        margin-left: .25rem;
        margin-right: .25rem;
        font-weight: bold; } }
    .stepper {
      padding-left: 0;
      padding-right: 0; } } }

// 付款狀態
.payment {
  border-radius: $border-radius;
  border: 1px solid rgba(#aaa, .3);
  color: rgba(#333, .8);
  padding: .5rem 1rem;
  margin: 16px 0 8px 0;
  .icon {
    padding-right: .75rem;
    font-size: 2rem; }
  .text {
    font-weight: 400; }
  .last5Code {
    font-size: .8rem;
    opacity: .9;
    .code {
      font-family: $monospace-font; } }
  &.paid {
    background-color: $color-success;
    border: 0;
    color: white; } }

// 報名成功
.success-title {
  font-size: 16px;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: 1px;
  .icon {
    color: $color-success;
    font-size: 5rem;
    margin-bottom: .5rem;
    animation: bounce-in .7s .3s;
    animation-fill-mode: backwards; }
  .text {
    color: $color-success;
    animation: move-down .3s .5s;
    animation-fill-mode: backwards; } }

// 報名注意事項
.form-note-text {
  color: $color5;
  margin: .75rem;
  padding: .75rem 1rem;
  background-color: rgba(#eee, .5);
  border-radius: $border-radius;
  font-size: 14px; }


// 查詢付款資訊頁面
.payment-info {
  .title {
    font-weight: bolder;
    text-align: center; }
  .content {
    margin-top: 1.5rem;
    .item {
      display: flex;
      justify-content: space-between;
      margin-top: .75rem;
      margin-bottom: .75rem;
      .value {
        font-weight: bolder;
        white-space: nowrap; }
      .explain {
        font-size: .7rem;
        opacity: .6; } }
    .paid {
      color: green;
      font-weight: bolder; }
    .unpaid {
      color: red;
      font-weight: bolder; }
    .btn-group {
      display: block;
      text-align: right;
      margin-top: 1rem; } } }
