@import "../variable";
@import "./common";

@keyframes mcip-payment-in {
  from {
    transform: translateX(-.5rem);
    opacity: 0; }
  to {
    opacity: 1; } }

// [學校]
.school {
  .bg-cover {
    animation: none;
    background-size: cover;
    background-position: center center;
    .header {
      height: 100%;
      z-index: 100;
      .container {
        height: 100%; }
      .row {
        height: 100%;
        align-items: center; }
      .period {
        color: white;
        font-size: 1.2rem;
        font-weight: 500; }
      .name {
        color: white;
        font-weight: bold;
        font-size: 2rem; } } }
  .date-list {
    margin-bottom: .25rem;
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    .date {
      margin-bottom: .25rem;
      font-size: .8rem;
      margin-right: 1rem;
      .icon {
        margin-right: .25rem;
        font-size: 1rem; } } }

  .participate {
    margin: .75rem 0;
    .button {
      .content {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        .text {
          font-size: 2rem;
          font-weight: bold; }
        .date {} } } }
  .list {
    // 修正icon寬度不一
    .icon {
      width: 24px; }
    .count {
      font-weight: bold;
      display: inline-block;
      font-size: .9rem;
      vertical-align: middle; } }

  // 使用樂台計劃金流文字
  .mcip-payment {
    font-size: .8rem;
    margin-bottom: .25rem;
    span {
      display: inline-block; }
    .icon {
      color: #00d7ff;
      margin-right: .5rem;
      animation: mcip-payment-in .4s .2s;
      animation-fill-mode: backwards; }
    .text {
      font-weight: 500;
      color: #555;
      animation: mcip-payment-in .4s .4s;
      animation-fill-mode: backwards;
      @include gradient-text($bg-gradient); } } }
