@import "../variable";

.news-card {
  margin: .75rem 0;
  box-shadow: none !important;
  .card-header {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none; }
  .card-content, .card-content-more {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 12px !important; }
  .content-hide {
    max-height: 180px;
    overflow: hidden; }
  .cover-photo {
    height: auto;
    width: 100%;
    object-fit: cover;
    background: #FFFAFA; }
  .avatar {
      background: $brand-primary; } }
