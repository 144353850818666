@import "../variable";

$image-height: 160px;
$image-margin: .15rem;

.comment-item {
  @include block-item-common;
  padding: 1rem 1rem;
  h6 {
    margin-bottom: .2rem;
    font-size: 1.2rem;
    font-weight: 400; }
  .school {
    small {
      font-size: .8rem;
      color: grey;
      margin-left: .5rem; } }
  .song {}

  .comment {
    .score {
      color: $brand-primary;
      font-size: 2rem;
      .label {
        margin: 0 .25rem;
        font-size: .8rem;
        color: grey; } }
    .content {
      white-space: pre-wrap;
      margin-bottom: 0; } }

  .img-wrapper {
    width: 100%;
    min-height: $image-height;
    max-height: calc(#{$image-height} * 2 + #{$image-margin} * 4);
    margin-top: .75rem;
    overflow: auto; }

  .img {
    display: inline-block;
    margin: $image-margin;
    img {
      border-radius: 3px;
      width: 100%;
      height: $image-height;
      object-fit: cover; } } }

.comment-image-modal {
  .icon-wrapper {
    text-align: right;
    padding: 12px 0; }
  .image {
    max-width: 100%;
    height: auto; } }
