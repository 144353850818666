@import "../variable";

.news-list {
    .news-card {
        .avatar {
            background: $brand-primary; } }
    .error-msg {
        text-align: center;
        opacity: .6;
        font-size: 80%; } }
